<script setup lang="ts">
</script>

<template>
  <details class="question-container">
    <summary class="question">
      <span class="question-text">
        <slot></slot>
      </span>
      <IconChevronDown class="chevron" />
    </summary>
    <div class="answer">
      <slot name="text"></slot>
    </div>
  </details>
</template>

<style scoped lang="scss">
summary {
  list-style: none;
  &::-webkit-details-marker {
    display: none;
  }
}
.question-container {
  padding: 24px;
  width: 100%;
  border-radius: 24px;
  background-color: #F7F8FC;
}
.question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.question-text {
  color: #262633;
  font-family: 'Roboto';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  @media (max-width: 1023px) {
    font-size: 18px;
    line-height: 24px;
  }
}
.chevron {
  display: flex;
  flex-shrink: 0;
  min-width: 24px;
  font-size: 24px;
  margin: 0;
}
details[open] .chevron {
  transform: rotate(180deg);
}
.answer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 24px;

  color: #262633;
  font-feature-settings: 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
</style>
